import { type FunctionComponent } from 'react';
import cx from 'clsx';

import ConfigurableSlot from '@/utils/ads/googlePublisherTag/ConfigurableAdSlot';

import { type IntegratedAdProps } from './IntegratedAd.props';

const INTEGRATED_AD_TARGETING = { fluid: 'integrated' };

const IntegratedAd: FunctionComponent<IntegratedAdProps> = ({
  device = 'all',
  pageTargeting,
}) => {
  const adSizeMap: googletag.SizeMappingArray = [
    [[1024, 0], device !== 'mobile' ? ['fluid'] : []], // Desktop and Laptop
    [[768, 0], ['fluid']], // Tablet
    [[0, 0], device !== 'desktop' ? ['fluid'] : []], // Mobile
  ];

  return (
    <ConfigurableSlot
      className={cx(
        'col-span-4 min-h-48 md:col-span-8 lg:col-span-5 xl:col-span-8 [&_iframe]:shadow-lg',
        {
          'hidden lg:block': device === 'desktop',
          'lg:hidden': device === 'mobile',
        },
      )}
      pageTargeting={pageTargeting}
      size={['fluid']}
      sizeMapping={adSizeMap}
      targeting={INTEGRATED_AD_TARGETING}
    />
  );
};

export default IntegratedAd;
