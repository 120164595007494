'use client';

import { type FunctionComponent } from 'react';
import { AddCommentButtonStrings as strings } from '@/translations';
import { mdiLoading } from '@mdi/js';
import Icon from '@mdi/react';
import { Button } from '@motortrend/ids';

import { DataId } from '@/utils/nitrous/constants';

import { UserCommentProfileStatus } from '@/store/constants';

import { type AddCommentButtonProps } from './AddCommentButton.props';

const AddCommentButton: FunctionComponent<AddCommentButtonProps> = ({
  addCommentText,
  isLoadingComment,
  onAddComment,
  onIsLoadingComment,
  onSignUp,
  userCommentProfileStatus,
}) => {
  const handleSignUpUser = () => {
    onSignUp?.();
  };

  const handleAddComment = async () => {
    onIsLoadingComment(true);
    await onAddComment();
    onIsLoadingComment(false);
  };

  addCommentText = addCommentText || strings.ButtonText;

  return (
    <>
      {userCommentProfileStatus === UserCommentProfileStatus.Pending ? (
        <Button
          aria-label={strings.ButtonTextSignUpToComment}
          data-id={DataId.SignupToComment}
          data-parent={DataId.Comment}
          onClick={handleSignUpUser}
        >
          {strings.ButtonTextSignUpToComment}
        </Button>
      ) : (
        <div>
          <Button
            aria-label={addCommentText}
            data-id={DataId.AddCommentButton}
            data-parent={DataId.Commenting}
            disabled={isLoadingComment}
            onClick={handleAddComment}
            startIcon={
              isLoadingComment ? (
                <Icon className="animate-spin" path={mdiLoading} size={1} />
              ) : null
            }
          >
            {addCommentText}
          </Button>
        </div>
      )}
    </>
  );
};

export default AddCommentButton;
