'use client';

import { useState, type FunctionComponent } from 'react';
import { EditCommentStrings as strings } from '@/translations';

import AddCommentButton from '../AddCommentButton';
import CancelReplyButton from '../CancelReplyButton';
import CommentsTextArea from '../CommentsTextArea';
import { type EditCommentProps } from './EditComment.props';

const EditComment: FunctionComponent<EditCommentProps> = ({
  articleId,
  commentId,
  commentText,
  onCancelEditComment,
  onEditComment,
  userCommentProfileStatus,
}) => {
  const [userText, setUserText] = useState(commentText);
  const [isLoadingComment, setIsLoadingComment] = useState<boolean>(false);

  const handleTextAreaChange = (text: string) => {
    setUserText(text);
  };

  const handleIsLoadingComment = (isLoading: boolean) => {
    setIsLoadingComment(isLoading);
  };

  const handleAddComment = () => {
    onEditComment(userText);
  };

  return (
    <>
      <CommentsTextArea
        commentText={userText}
        isInEditMode
        isReply
        onTextAreaChange={handleTextAreaChange}
        parentCommentId={commentId}
        rootId={articleId}
      />
      <div className="flex items-center justify-end gap-4">
        {!isLoadingComment ? (
          <CancelReplyButton onCancel={onCancelEditComment} />
        ) : null}
        <AddCommentButton
          addCommentText={strings.ButtonText}
          isLoadingComment={isLoadingComment}
          onAddComment={handleAddComment}
          onIsLoadingComment={handleIsLoadingComment}
          userCommentProfileStatus={userCommentProfileStatus}
        />
      </div>
    </>
  );
};

export default EditComment;
