import { useState } from 'react';

type UseStoredTextReturnType = [string, (value: string) => void];

export const useStoredText = (
  key?: string,
  initialValue = '',
): UseStoredTextReturnType => {
  const [storedValue, setStoredValue] = useState(() => {
    if (!key) {
      return initialValue;
    }
    return typeof window !== 'undefined'
      ? sessionStorage.getItem(key) || initialValue
      : initialValue;
  });

  const setValue = (value: string) => {
    if (key) {
      sessionStorage.setItem(key, value);
    }
    setStoredValue(value);
  };

  return [storedValue, setValue];
};
