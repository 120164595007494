'use client';

import {
  type AriaAttributes,
  type FunctionComponent,
  type ReactNode,
} from 'react';
import { CommentAlertStrings as strings } from '@/translations';
import { Alert, Button, Typography, type AlertProps } from '@motortrend/ids';

import { type CommentError } from '@/types/Comments';

import {
  commentError,
  commentModerationStatus,
  modalConfirmAction,
} from '@/utils/comments/constants';
import { DataId } from '@/utils/nitrous/constants';

import { type CommentAlertProps } from './CommentAlert.props';

const AlertButton: FunctionComponent<{
  'aria-label': AriaAttributes['aria-label'];
  'onClick': () => void;
  'text': string;
}> = ({ 'aria-label': ariaLabel, onClick, text }) => (
  <Button
    aria-label={ariaLabel}
    className="bg-transparent hover:bg-transparent"
    colorScheme="neutral-8"
    data-id={DataId.CommentAlertButton}
    data-parent={DataId.Commenting}
    onClick={onClick}
    size="small"
    typographyVariant="caption1"
  >
    {text}
  </Button>
);

const AlertContent: FunctionComponent<{
  children: ReactNode | ReactNode[];
  text: string;
}> = ({ children, text }) => (
  <>
    <Typography variant="caption1">{text}</Typography>
    <div className="-ml-4 flex">{children}</div>
  </>
);

const ResendCommentAlert: FunctionComponent<{
  onResendComment: () => void;
  text: string;
}> = ({ onResendComment, text }) => (
  <AlertContent text={text}>
    <AlertButton
      aria-label="Resend Comment"
      onClick={onResendComment}
      text={strings.ResendCommentAction}
    />
  </AlertContent>
);

const UpdateOrDeleteCommentAlert: FunctionComponent<{
  onDeleteComment: () => void;
  onSwitchToEditMode: () => void;
  text: string;
}> = ({ onDeleteComment, onSwitchToEditMode, text }) => (
  <AlertContent text={text}>
    <AlertButton
      aria-label="Edit Comment"
      onClick={onSwitchToEditMode}
      text={strings.EditCommentAction}
    />
    <AlertButton
      aria-label="Delete Comment"
      onClick={onDeleteComment}
      text={strings.DeleteCommentAction}
    />
  </AlertContent>
);

const getAlertContentForError = (
  error: CommentError | undefined,
  onDeleteComment: () => void,
  onSwitchToEditMode: () => void,
  onResendComment: () => void,
) => {
  if (error === commentError.publishError) {
    return (
      <ResendCommentAlert
        onResendComment={onResendComment}
        text={strings.PublishErrorAlert}
      />
    );
  } else if (error === commentError.offensiveLanguage) {
    return (
      <UpdateOrDeleteCommentAlert
        onDeleteComment={onDeleteComment}
        onSwitchToEditMode={onSwitchToEditMode}
        text={strings.OffensiveLanguageAlert}
      />
    );
  }

  return (
    <UpdateOrDeleteCommentAlert
      onDeleteComment={onDeleteComment}
      onSwitchToEditMode={onSwitchToEditMode}
      text={strings.ModerationRejectionAlert}
    />
  );
};

const CommentAlert: FunctionComponent<CommentAlertProps> = ({
  error,
  isCommentDeleted,
  moderationStatus,
  onModalAction,
  onResendComment,
  onSwitchToEditMode,
}) => {
  const handleDeleteComment = () => {
    onModalAction(modalConfirmAction.delete);
  };

  const getAlertProps = () => {
    if (isCommentDeleted) {
      return {
        children: (
          <Typography variant="caption1">
            {strings.DeletedCommentAlert}
          </Typography>
        ),
        severity: 'error',
      };
    }

    if (error || moderationStatus === commentModerationStatus.REJECTED) {
      return {
        children: getAlertContentForError(
          error,
          handleDeleteComment,
          onSwitchToEditMode,
          onResendComment,
        ),
        severity: 'error',
      };
    }

    return {
      children: (
        <Typography variant="caption1">
          {strings.ModerationPendingAlert}
        </Typography>
      ),
      severity: 'success',
    };
  };

  const alertProps = getAlertProps() as AlertProps;

  return <Alert className="mb-4" {...alertProps} />;
};

export default CommentAlert;
