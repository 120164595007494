import dayjs from 'dayjs';

/**
 * Function to handle subtitle.
 * @param subTitle - subtitle text
 * @returns - modified subtitle with a space after comma.
 */
const generateSubtitle = (author: string, published: string) => {
  const subtitle = [];
  if (author) {
    subtitle.push(author?.split(',')?.join(', '));
  }
  if (dayjs(published).isValid()) {
    subtitle.push(dayjs(published).format('MMM D, YYYY'));
  }
  return subtitle.join(' | ');
};

export default generateSubtitle;
