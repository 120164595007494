'use client';

import { useState, type FunctionComponent } from 'react';
import { Avatar, Typography } from '@motortrend/ids';
import cx from 'clsx';

import { commentHandlerAction } from '@/utils/comments/constants';
import { createStorageKey } from '@/utils/comments/createStorageKey';
import { useStoredText } from '@/hooks/useStoredText';
import AddCommentButton from '@/components/Comments/AddCommentButton';
import CancelReplyButton from '@/components/Comments/CancelReplyButton';

import CommentsTextArea from '../CommentsTextArea';
import { type AddCommentProps } from './AddComment.props';

const AddComment: FunctionComponent<AddCommentProps> = ({
  avatarUrl,
  hasReplies = false,
  isLastChild = false,
  isReply = false,
  onChangeActiveReplyCommentId,
  onCommentAction,
  onSignUp,
  parentCommentId = '',
  rootId,
  subjectId,
  userCommentProfileStatus,
  userName,
}) => {
  const storageKey = createStorageKey(parentCommentId, subjectId);
  const [commentText, setCommentText] = useStoredText(storageKey);
  const [isLoadingComment, setIsLoadingComment] = useState<boolean>(false);

  const handleTextAreaChange = (text: string) => {
    setCommentText(text);
  };

  const handleAddComment = async () => {
    await onCommentAction(
      commentHandlerAction.add,
      subjectId,
      commentText,
      parentCommentId,
      rootId,
    );
    setCommentText('');
    onChangeActiveReplyCommentId(null);
  };

  const handleTextAreaFocus = () => {
    if (!isReply) {
      onChangeActiveReplyCommentId(null);
    }
  };

  const handleCancelReply = () => {
    onChangeActiveReplyCommentId(null);
  };

  const handleIsLoadingComment = (isLoading: boolean) => {
    setIsLoadingComment(isLoading);
  };

  return (
    <div
      className={cx('flex-1', {
        "relative before:absolute before:-left-9 before:top-0 before:h-full before:border-l before:border-neutral-8 before:content-['']":
          isLastChild && !hasReplies,
      })}
    >
      {isReply ? (
        <div
          className={cx('flex items-center', {
            "relative after:absolute after:-left-9 after:-top-8 after:h-14 after:w-8 after:rounded-bl-lg after:border-b after:border-l after:border-neutral-5 after:content-['']":
              true,
          })}
        >
          <Avatar className="m-2 ml-0" height={2} src={avatarUrl} width={2}>
            {userName}
          </Avatar>
          <Typography variant="subtitle1">{userName}</Typography>
        </div>
      ) : null}
      <CommentsTextArea
        commentText={commentText}
        isReply={isReply}
        onTextAreaChange={handleTextAreaChange}
        onTextAreaFocus={handleTextAreaFocus}
        parentCommentId={parentCommentId}
        rootId={rootId}
      />
      <div className="flex items-center justify-end gap-4">
        {isReply && !isLoadingComment ? (
          <CancelReplyButton onCancel={handleCancelReply} />
        ) : null}
        <AddCommentButton
          isLoadingComment={isLoadingComment}
          onAddComment={handleAddComment}
          onIsLoadingComment={handleIsLoadingComment}
          onSignUp={onSignUp}
          userCommentProfileStatus={userCommentProfileStatus}
        />
      </div>
    </div>
  );
};

export default AddComment;
