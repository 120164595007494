'use client';

import { type FunctionComponent } from 'react';
import { LayoutGrid, LayoutGridContent, LayoutGridRail } from '@motortrend/ids';

import StickyModuleWrapper from '@/modules/StickyModuleWrapper';

import AdContainer from '../AdContainer/AdContainer';
import { type SectionRightRailProps } from './SectionRightRail.props';

const SectionRightRail: FunctionComponent<SectionRightRailProps> = ({
  children,
  className,
  isSponsored,
  pageTargeting,
  railSizes,
  railTargeting,
}) => {
  return (
    <LayoutGrid>
      <LayoutGridContent className={className}>{children}</LayoutGridContent>
      <LayoutGridRail className="hidden lg:block lg:h-full">
        <StickyModuleWrapper>
          {railSizes.map((size, index) => (
            <AdContainer
              adContainerProps={{ className: 'min-h-[250px] min-w-[300px]' }}
              adProps={{
                destroyRefresh: isSponsored,
                pageTargeting,
                sizes: size,
                targeting: railTargeting[index],
              }}
              key={index}
            />
          ))}
        </StickyModuleWrapper>
      </LayoutGridRail>
    </LayoutGrid>
  );
};

export default SectionRightRail;
