'use client';

import { type FunctionComponent } from 'react';
import { CommentMenuStrings as strings } from '@/translations';
import { mdiDotsVertical, mdiPencilOutline, mdiTrashCanOutline } from '@mdi/js';
import Icon from '@mdi/react';
import {
  IconButton,
  Menu,
  MenuContent,
  MenuItem,
  MenuTrigger,
} from '@motortrend/ids';

import { modalConfirmAction } from '@/utils/comments/constants';
import { DataId } from '@/utils/nitrous/constants';

import { type CommentMenuProps } from './CommentMenu.props';

const CommentMenu: FunctionComponent<CommentMenuProps> = ({
  onModalAction,
  onSwitchToEditMode,
}) => {
  const handleEditComment = () => {
    onSwitchToEditMode();
  };

  const handleDeleteComment = () => {
    onModalAction(modalConfirmAction.delete);
  };

  return (
    <Menu>
      <MenuTrigger className="!p-0">
        <IconButton
          aria-label="Comment Menu"
          colorScheme="neutral-8"
          compact
          data-id={DataId.CommentMenuIconButton}
          data-parent={DataId.Commenting}
        >
          <Icon path={mdiDotsVertical} size={1} />
        </IconButton>
      </MenuTrigger>
      <MenuContent>
        <MenuItem
          onSelect={handleEditComment}
          startAdornment={<Icon path={mdiPencilOutline} size={1} />}
          typographyVariant="body2"
        >
          {strings.ButtonTextEdit}
        </MenuItem>
        <MenuItem
          onSelect={handleDeleteComment}
          startAdornment={<Icon path={mdiTrashCanOutline} size={1} />}
          typographyVariant="body2"
        >
          {strings.ButtonTextDelete}
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

export default CommentMenu;
