'use client';

import { type FunctionComponent } from 'react';
import { CommentActionBarStrings as strings } from '@/translations';
import { mdiMessageReplyOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Button, Typography } from '@motortrend/ids';

import { getCommentTimeDuration } from '@/utils/comments/getCommentTimeDuration';
import { DataId } from '@/utils/nitrous/constants';

import { type CommentActionBarProps } from './CommentActionBar.props';

const CommentActionBar: FunctionComponent<CommentActionBarProps> = ({
  onClickIsReplying,
  timestamp,
}) => {
  const commentTimestamp = getCommentTimeDuration(timestamp);

  return (
    <div className="my-2 flex items-center justify-between">
      <Button
        aria-label="Add Reply To Comment"
        className="!border-transparent !p-1"
        colorScheme="neutral-2"
        data-id={DataId.ReplyToComment}
        data-parent={DataId.Comment}
        onClick={onClickIsReplying}
        size="small"
        startIcon={<Icon horizontal path={mdiMessageReplyOutline} size={1} />}
        typographyVariant="button2"
        variant="outlined"
      >
        {strings.AddReplyButtonText}
      </Button>
      <Typography variant="caption2">{commentTimestamp}</Typography>
    </div>
  );
};

export default CommentActionBar;
