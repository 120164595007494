import { type FunctionComponent } from 'react';
import { SocialShareWrapperStrings as strings } from '@/translations';
import { Typography } from '@motortrend/ids';

import { Layout, type SocialShareWrapperProps } from '@/components/SocialShare';

const SocialShareWrapper: FunctionComponent<SocialShareWrapperProps> = ({
  children,
  className,
  layout,
  showShareText,
}) => {
  return (
    <div className={`${className} text-center`}>
      {showShareText && (
        <Typography
          as="h6"
          className="pb-2"
          id="socialshare_title"
          variant="subtitle2"
        >
          {strings.Heading}
        </Typography>
      )}
      {layout === Layout.Article && (
        <div className="-top-1 z-20 m-3 flex h-8 w-auto items-center justify-center gap-4">
          {children}
        </div>
      )}
      {layout === Layout.Gallery && (
        <div className="flex gap-4">{children}</div>
      )}
      {layout === Layout.Other && (
        <div className="flex w-auto justify-between gap-4 pl-2">
          <div className="flex gap-4">{children}</div>
        </div>
      )}
    </div>
  );
};

export default SocialShareWrapper;
