'use client';

import { useEffect, useState, type FunctionComponent } from 'react';
import { usePathname } from 'next/navigation';

import { type AdSizes } from '@/types/AdSizes';

import { adElementType } from '@/utils/articles/adElementType';
import AdContainer from '@/components/AdContainer/AdContainer';
import IntegratedAd from '@/components/IntegratedAd';
import NativoAd from '@/components/NativoAd';
import {
  NATIVO_AD_SIZE_MAPPING,
  NATIVO_AD_SIZES,
} from '@/components/NativoAd/NativoAd';
import StmAd from '@/components/StmAd';

import { type AdElementProps } from './AdElement.props';

const mobileArticleMrecAdSizes: AdSizes = {
  desktop: [],
  laptop: [],
  mobile: [[300, 250]],
  tablet: [[300, 250]],
};

const AdElement: FunctionComponent<AdElementProps> = ({
  adElement: { adType, targeting = {} },
  pageTargeting,
}) => {
  const pathname = usePathname();
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [pathname]);
  switch (adType) {
    case adElementType.mobileMrec:
      return (
        <AdContainer
          adContainerProps={{
            className: 'flex min-h-[250px] min-w-[300px] lg:hidden',
          }}
          adProps={{
            className: 'm-auto w-fit',
            pageTargeting,
            sizes: mobileArticleMrecAdSizes,
            targeting,
          }}
          key={key}
        />
      );
    case adElementType.stm:
      // STM ads are all fluid, height varies based on container?
      return (
        <div className="hidden min-h-[120px] md:block">
          <StmAd pageTargeting={pageTargeting} />
        </div>
      );
    case adElementType.stmMobile:
      return (
        <div className="block min-h-36 md:hidden">
          <StmAd isMobile pageTargeting={pageTargeting} />
        </div>
      );
    case adElementType.ntv:
      return (
        <div className="hidden lg:flex">
          <NativoAd
            className="m-auto flex min-h-60 w-full flex-col justify-center"
            pageTag="Article"
            pageTargeting={pageTargeting}
            size={NATIVO_AD_SIZES.DesktopNativo}
            sizeMap={NATIVO_AD_SIZE_MAPPING.DesktopNativo}
          />
        </div>
      );
    case adElementType.ntvMobile:
      return (
        <div className="flex lg:hidden">
          <NativoAd
            className="m-auto flex min-h-48 w-full flex-col justify-center"
            pageTag="Article"
            pageTargeting={pageTargeting}
            size={NATIVO_AD_SIZES.MobileNativo}
            sizeMap={NATIVO_AD_SIZE_MAPPING.MobileNativo}
          />
        </div>
      );
    case adElementType.integrated:
      return <IntegratedAd device="desktop" pageTargeting={pageTargeting} />;
    case adElementType.integratedMobile:
      return <IntegratedAd device="mobile" pageTargeting={pageTargeting} />;
  }
};

export default AdElement;
