/**
 * It turns out that URL.searchParams (URLSearchParams) uses application/x-www-form-urlencoded
 * encoding, instead of the expected utf-8 encoding used by encodeURI/encodeURIComponent. This
 * is ok in most cases, but can lead to subtle bugs when building urls. For instance, the character
 * " " is encoded as "+" by URLSearchParams, while it is encoded as "%20" by encodeURI
 *
 * @see https://github.com/whatwg/url/issues/491
 * inspired by: https://www.npmjs.com/package/query-string
 */
const queryString = {
  /**
   * Converts a query string to an object of keys and value pairs. Values are decoded
   * using decodeURIComponent. The inverse of this operation is `queryString.stringify()`
   */
  parse: (queryString: string) => {
    let qs = queryString;
    if (qs.startsWith('?')) {
      qs = qs.substring(1);
    }

    const queryParams = queryString.split('&');
    return queryParams.reduce((acc, qp) => {
      const [key, val] = qp.split('=');
      return { ...acc, [key]: decodeURIComponent(val) };
    }, {});
  },
  /**
   * Converts a object of key value pairs into a query string. Values are encoded
   * using encodeURIComponent. The inverse of this operation is `queryString.parse()`
   */
  stringify: (queryParams: Record<string, string>) => {
    return Object.entries(queryParams).reduce((acc, [key, val]) => {
      const prefix = !!acc ? '&' : '';
      return `${acc}${prefix}${key}=${encodeURIComponent(val)}`;
    }, '');
  },
};

export default queryString;
