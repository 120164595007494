import { type FunctionComponent } from 'react';
import {
  cardSkeletonHeight,
  desktopVisibleCardCount,
  mobileVisibleCardCount,
} from '@/constants/recommendations';
import { Skeleton } from '@motortrend/ids';

import { type RecommendationSkeletonsProps } from './RecommendationSkeletons.props';

const RecommendationSkeletons: FunctionComponent<
  RecommendationSkeletonsProps
> = ({ isDesktopOrTablet }) => {
  const count =
    (isDesktopOrTablet ? desktopVisibleCardCount : mobileVisibleCardCount) / 2;

  return (
    <>
      <div className="flex flex-col gap-4 sm:flex-row">
        {Array.from({ length: count }, (_, i) => (
          <Skeleton
            height={cardSkeletonHeight}
            key={`make-skeleton-${i}`}
            variant="rounded"
            width="100%"
          />
        ))}
      </div>
      <div className="mt-4 flex flex-col gap-4 sm:flex-row">
        {Array.from({ length: count }, (_, i) => (
          <Skeleton
            height={cardSkeletonHeight}
            key={`make-skeleton-${count + i}`}
            variant="rounded"
            width="100%"
          />
        ))}
      </div>
    </>
  );
};

export default RecommendationSkeletons;
