import { useState } from 'react';

export const useShowRepliesState = () => {
  const [showRepliesCommentIds, setShowRepliesCommentIds] = useState<string[]>(
    [],
  );

  const handleToggleShowReplies = (commentId: string) => {
    const newShowRepliesCommentIds = showRepliesCommentIds.includes(commentId)
      ? showRepliesCommentIds.filter((id) => id !== commentId)
      : [...showRepliesCommentIds, commentId];

    setShowRepliesCommentIds(newShowRepliesCommentIds);
  };

  const handleShowReplies = (commentId: string) => {
    const newShowRepliesCommentIds = showRepliesCommentIds.includes(commentId)
      ? showRepliesCommentIds
      : [...showRepliesCommentIds, commentId];

    setShowRepliesCommentIds(newShowRepliesCommentIds);
  };

  return [
    showRepliesCommentIds,
    handleShowReplies,
    handleToggleShowReplies,
  ] as const;
};
