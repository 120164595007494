'use client';

import { useEffect, useRef, type FunctionComponent } from 'react';
import { env } from '@/config';

import loadScript from '@/utils/onetrust/loadScript';
import replacePlaceholders from '@/utils/replacePlaceholders';

import { type EmbedElementProps } from './EmbedElement.props';

const EmbedElement: FunctionComponent<EmbedElementProps> = ({
  embedElement: { embed },
}) => {
  const embedElementRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    const { key, url } = env.iframely;

    // Load Iframely Embed.js script and get the promise
    const iframelyScriptPromise = loadScript(
      replacePlaceholders(url, { key }),
    ) as Promise<void>;

    // Wait for the script to be loaded
    iframelyScriptPromise
      .then(() => {
        if (embedElementRef.current) {
          window.iframely.load(embedElementRef.current);
        }
      })
      .catch((error: Error) => {
        console.error('Error loading embed script:', error);
      });
  }, []);

  return (
    <div
      className="min-h-200 mb-6"
      data-c="embed"
      data-component="EmbedElement"
    >
      {/* eslint-disable-next-line @motortrend/nitrous/click-tracking */}
      <a href={embed} ref={embedElementRef} />
    </div>
  );
};

export default EmbedElement;
