'use client';

import { Suspense, useEffect, type FunctionComponent } from 'react';
import { withErrorBoundary } from 'react-error-boundary';

import { normalizeUrl } from '@/utils/normalizeUrl';
import { GdprCategories } from '@/utils/onetrust/constants';
import loadScript from '@/utils/onetrust/loadScript';
import { NoUiErrorFallback } from '@/components/ErrorFallback';

import { type OutbrainProps } from './Outbrain.props';

const Outbrain: FunctionComponent<OutbrainProps> = ({
  canonicalUrl,
  outbrainId,
}) => {
  useEffect(() => {
    loadScript('https://widgets.outbrain.com/outbrain.js', [
      GdprCategories.ADVERTISEMENT,
    ]);
  }, []);

  // useEffect(() => {
  //   // TODO: NTW-59 Integrate outbrain with client routing
  //   // the code in LW is currently calling one of the CBs before the script loads,
  //   //  and on client nav callbacks are triggered in 2 different places
  //   // https://developer.outbrain.com/
  //   handleOutbrainWidgetUpdate(canonicalUrl);
  // }, [canonicalUrl]);

  // TODO: The classes on the div below are TEMPORARY until we are able
  // to work with Outbrain to enable dark mode on their ads.
  return (
    <Suspense>
      <div className="rounded bg-neutral-8 px-2 pb-2" key={outbrainId}>
        <div
          className="OUTBRAIN"
          data-src={normalizeUrl({ paths: [canonicalUrl] })}
          data-widget-id={outbrainId}
        />
      </div>
    </Suspense>
  );
};

export default withErrorBoundary(Outbrain, {
  FallbackComponent: NoUiErrorFallback,
});
