'use client';

import { useEffect, useState, type FunctionComponent } from 'react';
import { RelatedArticlesStrings as strings } from '@/translations';
import {
  Button,
  Card,
  CardContent,
  CardGrid,
  CardMedia,
  CardTitle,
  Typography,
  useContainerBreakpoint,
} from '@motortrend/ids';

import { type ImageTemplateProps } from '@/types/ImgSizes';

import { graphql } from '@/lib/gql';
import generateSubtitle from '@/utils/generateSubtitle';
import { DataId } from '@/utils/nitrous/constants';
import { normalizeUrl } from '@/utils/normalizeUrl';
import AkamaiImage, { type AkamaiImageProps } from '@/components/AkamaiImage';

import { type RelatedArticlesProps } from './RelatedArticles.props';

const DEFAULT_CARDS = 6;
const LOAD_MORE_CARDS = 18;
const ARTICLE_IMAGE_FALLBACK =
  'https://www.motortrend.com/uploads/2022/05/Ghosted-Out-Car.png';
export const RelatedArticlesDataFragment = graphql(`
  fragment RelatedArticlesData on ArticleInterface {
    id
    hed
    contributors {
      contributor {
        displayName
      }
    }
    publishedDate
    teaseImage {
      url
      altText
    }
    seo {
      canonicalUrl
    }
  }
`);

const RelatedArticles: FunctionComponent<RelatedArticlesProps> = ({
  articles = [],
  isLoadMore = false,
  sectionTitle = '',
}: RelatedArticlesProps) => {
  const [visibleCards, setVisibleCards] = useState(0);
  const totalCards = articles?.length || 0;
  const containsArticles = articles && articles.length > 0;
  const { ref: isDesktopRef, result: isDesktop } = useContainerBreakpoint('lg');
  const { ref: isTabletRef, result: isTablet } = useContainerBreakpoint('sm');

  useEffect(() => {
    if (isLoadMore) {
      const visibleCards = isDesktop || isTablet ? 18 : 6;
      setVisibleCards(visibleCards);
    } else {
      setVisibleCards(totalCards);
    }
  }, [isDesktop, isTablet, isLoadMore, totalCards]);

  const handleLoadMore = () => {
    const defaultCards =
      isDesktop || isTablet ? LOAD_MORE_CARDS : DEFAULT_CARDS;
    const newVisibleCards = Math.min(visibleCards + defaultCards, totalCards);
    setVisibleCards(newVisibleCards);
  };

  const template: ImageTemplateProps = {
    defaultSize: '35vw',
    sizeConfig: {
      lg: '35vw',
      sm: '100vw',
    },
  };

  return containsArticles ? (
    <section
      data-c="related-articles"
      ref={(el) => {
        isDesktopRef(el);
        isTabletRef(el);
      }}
    >
      <Typography as="h2" md="h4" variant="h5">
        {sectionTitle}
      </Typography>
      <CardGrid className="pt-4 sm:pt-6 md:!gap-6" columns={1} md={3}>
        {articles?.slice(0, visibleCards).map((article) => {
          const imgUrl = article?.teaseImage?.url || ARTICLE_IMAGE_FALLBACK;
          const altText = article?.teaseImage?.altText || '';
          const articleUrl = article?.seo?.canonicalUrl || '';
          const title = article?.hed || '';
          const contributor =
            article?.contributors?.[0]?.contributor?.displayName || '';
          const publishedDate = article?.publishedDate || '';
          const id = article?.id;

          const subtitle = generateSubtitle(contributor, publishedDate);
          return (
            <Card
              as="a"
              data-id={DataId.RelatedArticlesCard}
              data-parent={DataId.RelatedArticles}
              href={normalizeUrl({ paths: [articleUrl] })}
              key={id}
            >
              <CardMedia
                alt={altText}
                as={AkamaiImage}
                aspectRatio="16/9"
                imageProps={
                  {
                    template,
                  } as AkamaiImageProps
                }
                responsiveAspectRatio
                src={imgUrl}
              />
              <CardContent>
                <CardTitle
                  subtitle={subtitle}
                  title={title}
                  titleTypographyProps={{ as: 'h3' }}
                />
              </CardContent>
            </Card>
          );
        })}
      </CardGrid>
      {visibleCards < totalCards && (
        <Button
          className="mt-4 w-full"
          colorScheme="neutral-8"
          data-id={DataId.LoadMoreButton}
          data-parent="LoadMore"
          onClick={handleLoadMore}
        >
          {strings.ButtonText}
        </Button>
      )}
    </section>
  ) : null;
};

export default RelatedArticles;
