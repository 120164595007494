import { CommentRepliesStrings as strings } from '@/translations';

export const getShowHideRepliesText = (
  replyCount: number,
  showReplies: boolean,
) => {
  const isSingleReply = replyCount === 1;

  let showReplyText = strings.ShowReplyPlural;
  let hideReplyText = strings.HideReplyPlural;

  if (isSingleReply) {
    showReplyText = strings.ShowReply;
    hideReplyText = strings.HideReply;
  }

  return !showReplies ? `${replyCount} ${showReplyText}` : hideReplyText;
};
