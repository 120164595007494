import { RecommendationWidgetStrings as strings } from '@/translations';

import { RecommendationWidgetConfig } from '@/types/Recommendation';

import { Brand, RecommendationType } from '@/lib/gql/graphql';

export const defaultRecommendationCount = 16;
export const mobileVisibleCardCount = 6;
export const desktopVisibleCardCount = 8;
export const cardSkeletonHeight = 250;

export enum RecommendationGenre {
  HotRod = 'hotrod',
}

export const recommendationWidgetConfig: Partial<
  Record<Brand, RecommendationWidgetConfig>
> = {
  [Brand.Motortrend]: {
    types: [
      RecommendationType.All,
      RecommendationType.Article,
      RecommendationType.Vehicle,
      RecommendationType.Video,
    ],
    tabs: [
      { type: RecommendationType.All, label: strings.AllTab },
      { type: RecommendationType.Article, label: strings.ArticlesTab },
      { type: RecommendationType.Vehicle, label: strings.CarsTab },
      { type: RecommendationType.Video, label: strings.VideosTab },
    ],
  },
  [Brand.Hotrod]: {
    types: [RecommendationType.Article],
    genres: [RecommendationGenre.HotRod],
  },
};

export const defaultRecommendationConfig =
  recommendationWidgetConfig[Brand.Motortrend]!;
