'use client';

import { type FunctionComponent } from 'react';
import { Button } from '@motortrend/ids';
import cx from 'clsx';

import { getShowHideRepliesText } from '@/utils/comments/getShowHideRepliesText';
import { DataId } from '@/utils/nitrous/constants';

import Comment from '../Comment';
import { type CommentRepliesProps } from './CommentReplies.props';

const CommentReplies: FunctionComponent<CommentRepliesProps> = ({
  activeReplyCommentId,
  commentId,
  onChangeActiveReplyCommentId,
  onCommentAction,
  onSignUp,
  onToggleShowReplies,
  replies,
  showRepliesCommentIds,
  userCommentProfile,
  userCommentProfileStatus,
  userId,
}) => {
  const replyCount = replies.length;
  const showReplies = showRepliesCommentIds.includes(commentId);
  const showHideRepliesText = getShowHideRepliesText(replyCount, showReplies);

  const handleToggleShowReplies = () => {
    onToggleShowReplies(commentId);
  };

  return (
    <>
      <div
        className={cx({
          "relative after:absolute after:-left-9 after:-top-8 after:h-12 after:w-9 after:rounded-bl-lg after:border-b after:border-l after:border-neutral-5 after:content-['']":
            !showReplies,
        })}
      >
        <Button
          className="!border-transparent !p-1"
          colorScheme="neutral-2"
          data-id={DataId.ShowReplies}
          data-parent={DataId.Comment}
          onClick={handleToggleShowReplies}
          size="small"
          typographyVariant="button2"
          variant="outlined"
        >
          <span className="font-normal">{showHideRepliesText}</span>
        </Button>
      </div>
      {showReplies
        ? replies.map((comment, index) => (
            <Comment
              activeReplyCommentId={activeReplyCommentId}
              comment={comment}
              isLastChild={index === replyCount - 1}
              key={comment.id}
              onChangeActiveReplyCommentId={onChangeActiveReplyCommentId}
              onCommentAction={onCommentAction}
              onSignUp={onSignUp}
              onToggleShowReplies={onToggleShowReplies}
              showRepliesCommentIds={showRepliesCommentIds}
              userCommentProfile={userCommentProfile}
              userCommentProfileStatus={userCommentProfileStatus}
              userId={userId}
            />
          ))
        : null}
    </>
  );
};

export default CommentReplies;
