'use client';

import { useState, type FunctionComponent } from 'react';
import { Avatar } from '@motortrend/ids';
import cx from 'clsx';

import { type ModalConfirmAction } from '@/types/Comments';

import {
  commentAction,
  commentHandlerAction,
  commentModerationStatus,
  modalConfirmAction,
} from '@/utils/comments/constants';
import { DataId } from '@/utils/nitrous/constants';
import CollapsibleText from '@/components/CollapsibleText';
import CommentHeader from '@/components/Comments/CommentHeader';

import { UserCommentProfileStatus } from '@/store/constants';

import AddComment from '../AddComment';
import CommentActionBar from '../CommentActionBar';
import CommentAlert from '../CommentAlert';
import CommentModal from '../CommentModal';
import CommentReplies from '../CommentReplies';
import EditComment from '../EditComment';
import { type CommentProps } from './Comment.props';

const Comment: FunctionComponent<CommentProps> = ({
  activeReplyCommentId,
  comment,
  isLastChild = false,
  isRootComment = false,
  onChangeActiveReplyCommentId,
  onCommentAction,
  onSignUp,
  onToggleShowReplies,
  showRepliesCommentIds,
  userCommentProfile,
  userCommentProfileStatus,
  userId,
}) => {
  const [inEditMode, setInEditMode] = useState<boolean>(false);
  const [modalAction, setModalAction] = useState<ModalConfirmAction | null>(
    null,
  );

  const {
    error,
    id: commentId,
    moderationStatus,
    replies: { replies },
    rootCommentId,
    text: commentText,
    timestamp,
    userReference,
  } = comment;
  const avatarUrl = userReference?.imageUrl ?? '';
  const userName = userReference?.name ?? '';
  const isReplying = activeReplyCommentId === commentId;
  const hasReplies = !!replies.length;
  const isCommentDeleted = comment.action === commentAction.DELETE;
  const articleId = comment?.assetReference?.id || '';
  const isCurrentUser = userId === userReference?.id;
  const showAlert =
    isCurrentUser &&
    !!(
      isCommentDeleted ||
      error ||
      moderationStatus !== commentModerationStatus.APPROVED
    );

  const handleClickIsReplying = () => {
    if (userCommentProfileStatus === UserCommentProfileStatus.Pending) {
      onSignUp();
    } else {
      onChangeActiveReplyCommentId(!isReplying ? commentId : null);
    }
  };

  const handleSwitchToEditMode = () => {
    setInEditMode(true);
  };

  const handleEditComment = async (userText: string) => {
    await onCommentAction(commentHandlerAction.edit, commentId, userText);
    setInEditMode(false);
  };

  const handleCancelEditComment = () => {
    setInEditMode(false);
  };

  const handleCloseModal = () => {
    setModalAction(null);
  };

  const handleConfirmModalAction = async (action: ModalConfirmAction) => {
    if (action === modalConfirmAction.delete) {
      await handleDeleteComment();
      handleCloseModal();
    }
  };

  const handleDeleteComment = async () => {
    await onCommentAction(commentHandlerAction.delete, commentId, commentText);
  };

  const handleResendComment = () => {
    onCommentAction(commentHandlerAction.resend, commentId, commentText);
  };

  return (
    <div
      className={cx(
        'flex w-full border-solid border-neutral-5 py-4',
        {
          'border-b': isRootComment,
        },
        {
          "relative before:absolute before:left-6 before:top-[3.75rem] before:h-[calc(100%-7rem)] before:border-l before:border-neutral-5 before:content-['']":
            hasReplies || isReplying,
        },
        {
          "relative after:absolute after:-left-9 after:-top-4 after:h-14 after:w-10 after:rounded-bl-lg after:border-b after:border-l after:border-neutral-5 after:content-['']":
            !isRootComment,
        },
      )}
    >
      <Avatar className="m-2" height={2} src={avatarUrl} width={2}>
        {userName}
      </Avatar>
      <div
        className={cx('mt-2 flex-1 pl-3', {
          "relative before:absolute before:-left-[5.25rem] before:top-0 before:h-full before:border-l before:border-neutral-8 before:content-['']":
            isLastChild,
        })}
      >
        {inEditMode ? (
          <EditComment
            articleId={articleId}
            commentId={commentId}
            commentText={commentText}
            onCancelEditComment={handleCancelEditComment}
            onEditComment={handleEditComment}
            userCommentProfileStatus={userCommentProfileStatus}
          />
        ) : (
          <>
            <CommentHeader
              onModalAction={setModalAction}
              onSwitchToEditMode={handleSwitchToEditMode}
              showCommentMenu={isCurrentUser && !isCommentDeleted}
              userName={userName}
            />
            {showAlert ? (
              <CommentAlert
                error={error}
                isCommentDeleted={isCommentDeleted}
                moderationStatus={moderationStatus}
                onModalAction={setModalAction}
                onResendComment={handleResendComment}
                onSwitchToEditMode={handleSwitchToEditMode}
              />
            ) : null}
            {!isCommentDeleted ? (
              <>
                <CollapsibleText
                  aria-label="Expand Comment"
                  data-id={DataId.CollapsibleComment}
                  data-parent={DataId.Comment}
                >
                  {commentText}
                </CollapsibleText>
                <CommentActionBar
                  onClickIsReplying={handleClickIsReplying}
                  timestamp={timestamp}
                />
              </>
            ) : null}
          </>
        )}
        {isReplying ? (
          <AddComment
            avatarUrl={userCommentProfile.avatar}
            hasReplies={hasReplies}
            isLastChild
            isReply
            onChangeActiveReplyCommentId={onChangeActiveReplyCommentId}
            onCommentAction={onCommentAction}
            parentCommentId={commentId}
            rootId={rootCommentId}
            subjectId={articleId}
            userCommentProfileStatus={userCommentProfileStatus}
            userName={userCommentProfile.userName}
          />
        ) : null}
        {hasReplies ? (
          <CommentReplies
            activeReplyCommentId={activeReplyCommentId}
            commentId={commentId}
            onChangeActiveReplyCommentId={onChangeActiveReplyCommentId}
            onCommentAction={onCommentAction}
            onSignUp={onSignUp}
            onToggleShowReplies={onToggleShowReplies}
            replies={replies}
            showRepliesCommentIds={showRepliesCommentIds}
            userCommentProfile={userCommentProfile}
            userCommentProfileStatus={userCommentProfileStatus}
            userId={userId}
          />
        ) : null}
      </div>
      <CommentModal
        action={modalAction}
        onClose={handleCloseModal}
        onConfirmAction={handleConfirmModalAction}
      />
    </div>
  );
};

export default Comment;
