'use client';

import { useEffect, useRef, useState, type FunctionComponent } from 'react';
import { Button, Typography } from '@motortrend/ids';
import cx from 'clsx';

import {
  type CollapsibleTextProps,
  type MaxLines,
} from './CollapsibleText.props';

const MINIMIZED_TEXT = 'Read more';
const EXPANDED_TEXT = 'Read less';

const lineClampClasses: Record<MaxLines, string> = {
  1: 'line-clamp-1',
  2: 'line-clamp-2',
  3: 'line-clamp-3',
  4: 'line-clamp-4',
  5: 'line-clamp-5',
  6: 'line-clamp-6',
};

const CollapsibleText: FunctionComponent<CollapsibleTextProps> = ({
  'aria-label': ariaLabel,
  'children': text = '',
  'data-id': dataId,
  'data-parent': dataParent,
  maxLines = 3,
  typographyClassName = '',
  typographyVariant = 'body2',
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const textRef = useRef<HTMLParagraphElement>(null);
  const [isTruncated, setIsTruncated] = useState<boolean>(false);

  const checkTruncation = () => {
    const textElement = textRef.current;
    if (textElement) {
      const isCurrentlyTruncated =
        textElement.scrollHeight > textElement.clientHeight;
      setIsTruncated(isCurrentlyTruncated);
    }
  };

  useEffect(() => {
    checkTruncation();
    window.addEventListener('resize', checkTruncation);

    return () => {
      window.removeEventListener('resize', checkTruncation);
    };
  }, [text]);

  const handleToggleIsExpanded = () => {
    setIsExpanded((prevIsExpanded) => !prevIsExpanded);
  };

  return (
    <>
      <Typography
        className={cx(
          'text-neutral-3',
          typographyClassName,
          !isExpanded && lineClampClasses[maxLines],
        )}
        ref={textRef}
        variant={typographyVariant}
      >
        {text}
      </Typography>
      {(isTruncated || isExpanded) && (
        <Button
          aria-label={ariaLabel}
          className="border-0 !p-1"
          colorScheme="neutral-2"
          data-id={dataId}
          data-parent={dataParent}
          onClick={handleToggleIsExpanded}
          size="small"
          typographyVariant={typographyVariant}
          variant="outlined"
        >
          <span className={cx(typographyClassName, 'font-normal underline')}>
            {isExpanded ? EXPANDED_TEXT : MINIMIZED_TEXT}
          </span>
        </Button>
      )}
    </>
  );
};

export default CollapsibleText;
