import { type FunctionComponent, type ReactNode } from 'react';
import { Feature } from '@/config';
import cx from 'clsx';

const StickyModuleWrapper: FunctionComponent<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <div
      className={cx('sticky flex flex-col gap-6', {
        'top-14 lg:top-20': Feature.HotRodRelaunch === true,
        'top-4': Feature.HotRodRelaunch === false,
      })}
    >
      {children}
    </div>
  );
};

export default StickyModuleWrapper;
