import {
  type AddCommentPost,
  type BaseComment,
  type NestedComment,
} from '@/types/Comments';

import { commentAction, commentModerationStatus } from './constants';

export const createNestedComment = (
  id: string,
  commentPost: AddCommentPost,
  error?: BaseComment['error'],
) => {
  const {
    assetReference,
    parentId = '',
    rootCommentId,
    text,
    userReference,
  } = commentPost;

  const newComment: NestedComment = {
    action: commentAction.CREATE,
    assetReference,
    editable: true,
    error,
    id,
    moderationStatus: commentModerationStatus.PENDING,
    parentId,
    replies: {
      replies: [],
      totalCount: 0,
    },
    rootCommentId: rootCommentId || id || '',
    text,
    timestamp: new Date().toISOString(),
    userReference,
  };

  return newComment;
};
