const getPathSegments = (path: string): string[] => {
  if ('string' !== typeof path) {
    return [''];
  }

  // Remove leading and trailing slashes
  const cleanedPath = path.replace(/^\/|\/$/g, '');

  // Split the path into segments
  return cleanedPath.split('/');
};

export default getPathSegments;
