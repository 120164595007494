import { type ReactNode } from 'react';
import { type IconButtonProps, type TooltipProps } from '@motortrend/ids';

import { type DataId } from '@/utils/nitrous/constants';

export enum Layout {
  Article = 'article',
  Gallery = 'gallery',
  Other = 'other',
}

export type SocialShareProps = {
  className?: string;
  path: string;
  dataParent?: DataId;
  rounded?: IconButtonProps['rounded'];
  showShareText?: boolean;
  size?: IconButtonProps['size'];
  theme?: TooltipProps['theme'];
  title: string;
  layout?: Layout;
};

export type SocialShareWrapperProps = {
  children: ReactNode;
  className?: SocialShareProps['className'];
  layout: Layout;
  showShareText?: SocialShareProps['showShareText'];
};
