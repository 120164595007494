import {
  type BaseComment,
  type Comment,
  type NestedComment,
} from '@/types/Comments';

const filterReplies = (replies: NestedComment[]): NestedComment[] =>
  replies.filter((reply) => !replies.some((r) => r.id === reply.parentId));

const sortRepliesInAscendingOrderByTimestamp = (
  replies: NestedComment[],
): NestedComment[] =>
  replies.sort(
    (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
  );

const calculateTotalNestedRepliesCount = (
  nestedComment: NestedComment,
): number =>
  nestedComment.replies.replies.reduce(
    (acc, reply) => acc + 1 + calculateTotalNestedRepliesCount(reply),
    0,
  );

const buildNestedComments = (
  parentId: string,
  replies: BaseComment[],
): NestedComment[] =>
  replies
    .filter((reply) => reply.parentId === parentId)
    .map((reply) => {
      const nestedReplies = buildNestedComments(reply.id, replies);

      return {
        ...reply,
        replies: {
          replies: nestedReplies,
          totalCount: calculateTotalNestedRepliesCount({
            ...reply,
            replies: { replies: nestedReplies, totalCount: 0 },
          }),
        },
      } as NestedComment;
    });

export const mapNestedComments = (comments: Comment[]): NestedComment[] =>
  comments.map((comment) => {
    const nestedReplies = buildNestedComments(
      comment.id,
      comment.replies.replies,
    );

    return {
      ...comment,
      replies: {
        replies: sortRepliesInAscendingOrderByTimestamp(
          filterReplies(nestedReplies),
        ),
        totalCount: calculateTotalNestedRepliesCount({
          ...comment,
          replies: { replies: nestedReplies, totalCount: 0 },
        }),
      },
    };
  });
