'use client';

import { type FunctionComponent } from 'react';
import { CancelReplyButtonStrings as strings } from '@/translations';
import { Button } from '@motortrend/ids';

import { DataId } from '@/utils/nitrous/constants';

import { type CancelReplyButtonProps } from './CancelReplyButton.props';

const CancelReplyButton: FunctionComponent<CancelReplyButtonProps> = ({
  onCancel,
}) => {
  return (
    <Button
      aria-label="Cancel Reply"
      className="!border-transparent"
      colorScheme="neutral-2"
      data-id={DataId.CancelReplyButton}
      data-parent={DataId.Commenting}
      onClick={onCancel}
      variant="outlined"
    >
      {strings.ButtonText}
    </Button>
  );
};

export default CancelReplyButton;
