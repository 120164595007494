'use client';

import { type FunctionComponent } from 'react';

import {
  ModalActionDataIdMap,
  modalConfirmMessage,
} from '@/utils/comments/constants';
import { DataId } from '@/utils/nitrous/constants';
import ConfirmModal from '@/components/ConfirmModal';

import { type CommentModalProps } from './CommentModal.props';

// TODO: When commenting is reenabled and this component is needed again,
// it will need to be refactored to use the `Dialog` component from IDS.
const CommentModal: FunctionComponent<CommentModalProps> = ({
  action,
  onClose,
  onConfirmAction,
}) => {
  const modalConfig = action ? modalConfirmMessage[action] : null;

  const handleConfirmAction = () => {
    if (action) {
      onConfirmAction(action);
    }
  };

  return modalConfig ? (
    <ConfirmModal
      actionButtonText={modalConfig.buttonText}
      buttonConfig={{
        actionButton: {
          'data-id': ModalActionDataIdMap[action!].confirm,
          'data-parent': DataId.CommentMenuModal,
        },
        cancelButton: {
          'data-id': ModalActionDataIdMap[action!].cancel,
          'data-parent': DataId.CommentMenuModal,
        },
      }}
      data-parent={DataId.CommentMenuModal}
      isOpen
      message={modalConfig.message}
      onClose={onClose}
      onConfirmAction={handleConfirmAction}
    />
  ) : null;
};

export default CommentModal;
