'use client';

import {
  useCallback,
  useEffect,
  useState,
  type FunctionComponent,
} from 'react';
import { ReferenceId, SessionStorageKey } from '@/constants';
import useStore from '@/store';

import { type CommentData } from '@/types/Comments';

import { fetchComments } from '@/lib/interactionApi';
import scrollToElement from '@/utils/scrollToElement';

import {
  userIdSelector,
  userJWTSelector,
  userProfileSelector,
  userProfileStatusSelector,
} from '@/store/selectors';

import AddComment from './AddComment';
import { type CommentsProps } from './Comments.props';
import CommentsHeader from './CommentsHeader';
import CommentsThread from './CommentsThread';
import { useCommentHandler } from './hooks/useCommentHandler';
import useNestedCommentsState from './hooks/useNestedCommentsState';
import { useShowRepliesState } from './hooks/useShowRepliesState';

const Comments: FunctionComponent<CommentsProps> = ({ id, type }) => {
  const userId = useStore(userIdSelector) || '';
  const userJWT = useStore(userJWTSelector);
  const userCommentProfile = useStore(userProfileSelector);
  const userCommentProfileStatus = useStore(userProfileStatusSelector);

  const [
    nestedComments,
    processAndSetNestedComments,
    addNestedComment,
    updateNestedComment,
  ] = useNestedCommentsState(userId);

  const [activeReplyCommentId, setActiveReplyCommentId] = useState<
    string | null
  >(null);

  const [showRepliesCommentIds, onShowReplies, onToggleShowReplies] =
    useShowRepliesState();

  // Scroll to comments section after register / sign in
  useEffect(() => {
    if (sessionStorage.getItem(SessionStorageKey.ScrollToComments)) {
      // Opting for ID instead of ref to accommodate lazy loading comments
      scrollToElement(ReferenceId.Comments);

      sessionStorage.removeItem(SessionStorageKey.ScrollToComments);
    }
  }, []);

  useEffect(() => {
    const initializeCommentsData = async () => {
      if (userId && userJWT) {
        const commentData = (await fetchComments({
          id,
          token: userJWT,
          type,
          userId,
        })) as CommentData;

        if (commentData.comments) {
          processAndSetNestedComments(commentData.comments);
        }
      }
    };

    initializeCommentsData();
  }, [id, processAndSetNestedComments, type, userId, userJWT]);

  const handleCommentAction = useCommentHandler(
    id,
    type,
    userId,
    userJWT,
    userCommentProfile,
    addNestedComment,
    updateNestedComment,
    onShowReplies,
  );

  const handleChangeActiveReplyCommentId = (commentId?: string | null) => {
    if (commentId || commentId === null) {
      setActiveReplyCommentId(commentId);
    }
  };

  const handleSignUp = useCallback(() => {
    // @todo sort registration post Sonic
    sessionStorage.setItem(SessionStorageKey.ScrollToComments, 'true');
  }, []);

  return (
    <div
      className="grid scroll-my-16 grid-cols-4 gap-2 md:grid-cols-8 md:gap-8 xl:grid-cols-12"
      id={ReferenceId.Comments}
    >
      <section className="col-span-4 rounded-lg bg-neutral-8 py-4 md:col-span-8 lg:col-span-5 xl:col-span-8 dark:bg-neutral-1">
        <CommentsHeader />
        <AddComment
          avatarUrl={userCommentProfile.avatar}
          onChangeActiveReplyCommentId={handleChangeActiveReplyCommentId}
          onCommentAction={handleCommentAction}
          onSignUp={handleSignUp}
          subjectId={id}
          userCommentProfileStatus={userCommentProfileStatus}
          userName={userCommentProfile.userName}
        />
        <CommentsThread
          activeReplyCommentId={activeReplyCommentId}
          nestedComments={nestedComments}
          onChangeActiveReplyCommentId={handleChangeActiveReplyCommentId}
          onCommentAction={handleCommentAction}
          onSignUp={handleSignUp}
          onToggleShowReplies={onToggleShowReplies}
          showRepliesCommentIds={showRepliesCommentIds}
          userCommentProfile={userCommentProfile}
          userCommentProfileStatus={userCommentProfileStatus}
          userId={userId}
        />
      </section>
    </div>
  );
};

export default Comments;
