'use client';

import { useState, type FunctionComponent } from 'react';
import { CommentsThreadStrings as strings } from '@/translations';
import { Button } from '@motortrend/ids';

import {
  ADDITIONAL_VISIBLE_ROOT_COMMENT_COUNT,
  BASE_VISIBLE_ROOT_COMMENT_COUNT,
} from '@/utils/comments/constants';
import { DataId } from '@/utils/nitrous/constants';

import Comment from '../Comment';
import { type CommentsThreadProps } from './CommentsThread.props';

const CommentsThread: FunctionComponent<CommentsThreadProps> = ({
  activeReplyCommentId,
  nestedComments,
  onChangeActiveReplyCommentId,
  onCommentAction,
  onSignUp,
  onToggleShowReplies,
  showRepliesCommentIds,
  userCommentProfile,
  userCommentProfileStatus,
  userId,
}) => {
  const [visibleCommentCount, setVisibleCommentCount] = useState(
    BASE_VISIBLE_ROOT_COMMENT_COUNT,
  );

  const visibleComments = nestedComments.slice(0, visibleCommentCount);
  const hasHiddenComments = nestedComments.length > visibleCommentCount;

  const handleShowMoreComments = () => {
    setVisibleCommentCount(
      (prevCount) => prevCount + ADDITIONAL_VISIBLE_ROOT_COMMENT_COUNT,
    );
  };

  return (
    <div>
      {visibleComments.map((comment) => (
        <Comment
          activeReplyCommentId={activeReplyCommentId}
          comment={comment}
          isRootComment
          key={comment.id}
          onChangeActiveReplyCommentId={onChangeActiveReplyCommentId}
          onCommentAction={onCommentAction}
          onSignUp={onSignUp}
          onToggleShowReplies={onToggleShowReplies}
          showRepliesCommentIds={showRepliesCommentIds}
          userCommentProfile={userCommentProfile}
          userCommentProfileStatus={userCommentProfileStatus}
          userId={userId}
        />
      ))}
      {hasHiddenComments ? (
        <Button
          className="mt-10 w-full !border-transparent shadow-md"
          colorScheme="neutral-2"
          data-id={DataId.ShowMoreComments}
          data-parent={DataId.Commenting}
          onClick={handleShowMoreComments}
          variant="outlined"
        >
          {strings.ShowMoreComments}
        </Button>
      ) : null}
    </div>
  );
};

export default CommentsThread;
