import { CommentActionBarStrings as strings } from '@/translations';

export const getCommentTimeDuration = (commentTime: string): string => {
  const commentTimeStatement: Record<number, string> = {
    60: strings.Min,
    3600: strings.Hour, // eslint-disable-line sort-keys
    86400: strings.Day,
    604800: strings.Week, // eslint-disable-line sort-keys
    18144000: strings.Month, // eslint-disable-line sort-keys
  };

  const commentMilliSeconds = Date.parse(commentTime);
  const currentTimeMilliseconds = Date.now();

  const differenceInSeconds = Math.floor(
    (currentTimeMilliseconds - commentMilliSeconds) / 1000,
  );

  if (differenceInSeconds < 60) {
    return '';
  }

  const threshold = Object.keys(commentTimeStatement)
    .map(Number)
    .sort((a, b) => b - a)
    .find((t) => differenceInSeconds >= t)!;

  const duration = Math.floor(differenceInSeconds / threshold);
  const unit = commentTimeStatement[threshold];
  const pluralizedUnit = duration > 1 ? `${unit}s` : unit;

  return `${duration} ${pluralizedUnit} ${strings.CommentTimeStatementEnding}`;
};
