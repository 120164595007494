import { type FunctionComponent } from 'react';
import { RecommendationWidgetStrings as strings } from '@/translations';
import { mdiPlayCircle } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Button,
  Card,
  CardContent,
  CardGrid,
  CardMedia,
  CardTitle,
} from '@motortrend/ids';

import { type ImageTemplateProps } from '@/types/ImgSizes';

import { DataId } from '@/utils/nitrous/constants';
import AkamaiImage, { type AkamaiImageProps } from '@/components/AkamaiImage';
import RecommendationSkeletons from '@/components/Article/RecommendationWidget/RecommendationSkeletons';
import { NextLink } from '@/components/NextLink';

import { type RecommendationsGridProps } from './RecommendationsGrid.props';

const template: ImageTemplateProps = {
  defaultSize: '40vw',
  sizeConfig: {
    lg: '30vw',
    sm: '40vw',
  },
};

const RecommendationsGrid: FunctionComponent<RecommendationsGridProps> = ({
  handleLoadMore,
  isDesktopOrTablet,
  isFetching,
  recommendationItems,
  visibleCardCount,
}) => {
  if (isFetching) {
    return <RecommendationSkeletons isDesktopOrTablet={isDesktopOrTablet} />;
  }

  return (
    <>
      <CardGrid data-testid="card-grid" lg={4} spacing={1.5}>
        {recommendationItems.slice(0, visibleCardCount).map((content) => (
          <Card
            as={NextLink}
            data-id={DataId.YouMayAlsoLikeRecommendation}
            data-parent={`${DataId.ArticleBody}`}
            href={content.link || ''}
            key={content.title}
            orientation="vertical"
          >
            <CardMedia
              alt={content.title}
              as={AkamaiImage}
              aspectRatio="16/9"
              imageProps={
                {
                  priority: true,
                  template,
                } as AkamaiImageProps
              }
              key={content.title}
              src={content.thumbnailUrl || ''}
            >
              {content.type === 'video' && (
                <div className="absolute inset-0 flex items-center justify-center opacity-70 transition-opacity group-hover/card:opacity-100">
                  <Icon
                    className="size-16 text-neutral-8"
                    path={mdiPlayCircle}
                  />
                </div>
              )}
            </CardMedia>
            <CardContent>
              <CardTitle
                subtitle={content.subTitle}
                title={content.title}
                titleTypographyProps={{
                  md: 'subtitle1',
                  variant: 'subtitle2',
                }}
              />
            </CardContent>
          </Card>
        ))}
      </CardGrid>
      {!!visibleCardCount && visibleCardCount < recommendationItems?.length && (
        <Button
          className="mt-4 w-full"
          colorScheme="neutral-8"
          data-id={DataId.LoadMoreButton}
          data-parent={DataId.ArticleBody}
          onClick={() => handleLoadMore(recommendationItems)}
        >
          {strings.LoadMoreButton}
        </Button>
      )}
    </>
  );
};

export default RecommendationsGrid;
