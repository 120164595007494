import { useCallback } from 'react';
import { nitrous } from '@motortrend/nitrous-web-sdk';
import { v4 as uuidv4 } from 'uuid';

import {
  type AddCommentPost,
  type BaseComment,
  type CommentAction,
  type CommentActionHandler,
  type DeleteCommentPost,
  type EditCommentPost,
  type UserCommentProfile,
} from '@/types/Comments';

import { sendComment } from '@/lib/interactionApi';
import {
  channel,
  commentAction,
  commentError,
  commentHandlerAction,
  commentModerationStatus,
  language,
} from '@/utils/comments/constants';
import { createBaseCommentPost } from '@/utils/comments/createBaseCommentPost';
import { createNestedComment } from '@/utils/comments/createNestedComment';

export const useCommentHandler = (
  assetId: string,
  assetType: string,
  userId: string,
  userJWT: string,
  userCommentProfile: UserCommentProfile['data'],
  addNestedComment: (
    commentId: string,
    newCommentPost: AddCommentPost,
    error?: BaseComment['error'],
  ) => void,
  updateNestedComment: (
    commentId: string,
    updates: Partial<BaseComment>,
  ) => void,
  onShowReplies: (commentId: string) => void,
): CommentActionHandler => {
  const addNewComment = useCallback(
    async (commentText: string, parentId: string, rootCommentId: string) => {
      const newCommentPost: AddCommentPost = {
        ...createBaseCommentPost(
          assetId,
          assetType,
          userId,
          userCommentProfile,
          commentText,
        ),
        action: commentAction.CREATE,
        channel: channel.Reviews,
        language: language.english,
        parentId: rootCommentId ? parentId : undefined,
        rootCommentId: rootCommentId || undefined,
      };

      nitrous.events.logCommentPosted();
      const addCommentResponse = await sendComment(newCommentPost, userJWT);

      return { addCommentResponse, newCommentPost };
    },
    [assetId, assetType, userCommentProfile, userId, userJWT],
  );

  const editComment = useCallback(
    async (commentId: string, commentText: string) => {
      const editCommentPost: EditCommentPost = {
        ...createBaseCommentPost(
          assetId,
          assetType,
          userId,
          userCommentProfile,
          commentText,
        ),
        action: commentAction.UPDATE,
        id: commentId,
      };

      const editCommentResponse = await sendComment(editCommentPost, userJWT);

      return editCommentResponse;
    },
    [assetId, assetType, userCommentProfile, userId, userJWT],
  );

  const deleteComment = useCallback(
    async (commentId: string, commentText: string) => {
      const deleteCommentPost: DeleteCommentPost = {
        ...createBaseCommentPost(
          assetId,
          assetType,
          userId,
          userCommentProfile,
          commentText,
        ),
        action: commentAction.DELETE,
        id: commentId,
        language: language.english,
      };

      const deleteCommentResponse = await sendComment(
        deleteCommentPost,
        userJWT,
      );

      return deleteCommentResponse;
    },
    [assetId, assetType, userCommentProfile, userId, userJWT],
  );

  const addNestedErrorComment = useCallback(
    (
      statusCode: number | undefined,
      error: string | undefined,
      newCommentPost: AddCommentPost,
    ) => {
      if (statusCode === 422) {
        addNestedComment(
          `placeholderId-${uuidv4()}`,
          newCommentPost,
          commentError.offensiveLanguage,
        );
      } else if (statusCode === 409 || error) {
        addNestedComment(
          `placeholderId-${uuidv4()}`,
          newCommentPost,
          commentError.publishError,
        );
      }
    },
    [addNestedComment],
  );

  const updateNestedCommentWithError = useCallback(
    (
      statusCode: number | undefined,
      error: string | undefined,
      commentId: string,
      commentText: string,
    ) => {
      if (statusCode === 422) {
        updateNestedComment(commentId, {
          error: commentError.offensiveLanguage,
          text: commentText,
        });
      } else if (statusCode === 409 || error) {
        updateNestedComment(commentId, {
          error: commentError.publishError,
          text: commentText,
        });
      }
    },
    [updateNestedComment],
  );

  const handleAddComment = useCallback(
    async (commentText: string, parentId: string, rootCommentId: string) => {
      if (!commentText) {
        return;
      }

      const { addCommentResponse, newCommentPost } = await addNewComment(
        commentText,
        parentId,
        rootCommentId,
      );

      if (addCommentResponse?.data?.message === 'success') {
        addNestedComment(addCommentResponse.data.id, newCommentPost);
        onShowReplies(parentId);
      } else {
        addNestedErrorComment(
          addCommentResponse?.statusCode,
          addCommentResponse?.error,
          newCommentPost,
        );
        onShowReplies(parentId);
      }
    },
    [addNestedErrorComment, addNestedComment, addNewComment, onShowReplies],
  );

  const handleEditComment = useCallback(
    async (
      commentId: string | null,
      commentText: string,
      rootCommentId: string = '',
      parentId: string = '',
    ) => {
      if (!commentText || !commentId) {
        return;
      }

      if (isPlaceholderComment(commentId)) {
        const newCommentPost: AddCommentPost = {
          ...createBaseCommentPost(
            assetId,
            assetType,
            userId,
            userCommentProfile,
            commentText,
          ),
          action: commentAction.CREATE,
          channel: channel.Reviews,
          language: language.english,
          parentId: rootCommentId ? parentId : undefined,
          rootCommentId: rootCommentId || undefined,
        };
        const addCommentResponse = await sendComment(newCommentPost, userJWT);

        if (addCommentResponse?.data?.message === 'success') {
          const newComment = createNestedComment(
            addCommentResponse.data.id,
            newCommentPost,
          );
          updateNestedComment(commentId, newComment);
        } else {
          updateNestedCommentWithError(
            addCommentResponse?.statusCode,
            addCommentResponse?.error,
            commentId,
            commentText,
          );
        }
      } else {
        const editCommentResponse = await editComment(commentId, commentText);

        if (editCommentResponse?.data?.message === 'success') {
          updateNestedComment(commentId, {
            error: undefined,
            moderationStatus: commentModerationStatus.PENDING,
            text: commentText,
          });
        } else {
          updateNestedCommentWithError(
            editCommentResponse?.statusCode,
            editCommentResponse?.error,
            commentId,
            commentText,
          );
        }
      }
    },
    [
      assetId,
      assetType,
      editComment,
      updateNestedComment,
      updateNestedCommentWithError,
      userCommentProfile,
      userId,
      userJWT,
    ],
  );

  const handleDeleteComment = useCallback(
    async (commentId: string | null, commentText: string) => {
      if (!commentId) {
        return;
      }

      if (isPlaceholderComment(commentId)) {
        updateNestedComment(commentId, {
          action: commentAction.DELETE,
        });
      } else {
        const deleteCommentResponse = await deleteComment(
          commentId,
          commentText,
        );

        if (deleteCommentResponse?.data?.message === 'success') {
          updateNestedComment(commentId, {
            action: commentAction.DELETE,
          });
        }
      }
    },
    [deleteComment, updateNestedComment],
  );

  const handleResendComment = useCallback(
    async (
      commentId: string | null,
      commentText: string,
      parentId: string,
      rootCommentId: string,
    ) => {
      if (!commentId) {
        return;
      }
      const { addCommentResponse, newCommentPost } = await addNewComment(
        commentText,
        parentId,
        rootCommentId,
      );

      if (addCommentResponse?.data?.message === 'success') {
        updateNestedComment(
          commentId,
          createNestedComment(addCommentResponse.data.id, newCommentPost),
        );
      } else {
        updateNestedCommentWithError(
          addCommentResponse?.statusCode,
          addCommentResponse?.error,
          commentId,
          commentText,
        );
      }
    },
    [addNewComment, updateNestedComment, updateNestedCommentWithError],
  );

  const handleCommentAction = useCallback(
    async (
      action: CommentAction,
      commentId: string | null,
      commentText: string,
      parentId: string = '',
      rootCommentId: string = '',
    ) => {
      switch (action) {
        case commentHandlerAction.add:
          await handleAddComment(commentText, parentId, rootCommentId);
          break;
        case commentHandlerAction.edit:
          await handleEditComment(
            commentId,
            commentText,
            parentId,
            rootCommentId,
          );
          break;
        case commentHandlerAction.delete:
          await handleDeleteComment(commentId, commentText);
          break;
        case commentHandlerAction.resend:
          await handleResendComment(
            commentId,
            commentText,
            parentId,
            rootCommentId,
          );
          break;
      }
    },
    [
      handleAddComment,
      handleDeleteComment,
      handleEditComment,
      handleResendComment,
    ],
  );

  return handleCommentAction;
};

export function isPlaceholderComment(commentId: string): boolean {
  return commentId.startsWith('placeholderId-');
}
