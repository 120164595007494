import { type FunctionComponent } from 'react';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { Button, IconButton, Modal, Typography } from '@motortrend/ids';

import { DataId } from '@/utils/nitrous/constants';

import { type ConfirmModalProps } from './ConfirmModal.props';

const CANCEL_BUTTON_TEXT = 'Cancel';

// TODO: When commenting is reenabled and this component is needed again,
// it will need to be refactored to use the `Dialog` component from IDS.
const ConfirmModal: FunctionComponent<ConfirmModalProps> = ({
  actionButtonText,
  buttonConfig,
  isOpen,
  message,
  onClose,
  onConfirmAction,
}) => {
  const handleCloseModal = () => {
    onClose();
  };

  return (
    <Modal onClose={handleCloseModal} open={isOpen}>
      <div className="fixed left-1/2 top-1/2 grid w-96 max-w-lg -translate-x-1/2 -translate-y-1/2 gap-4 bg-neutral-8 p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg">
        <div className="flex justify-between">
          <Typography variant="h6">{message}</Typography>
          <IconButton
            colorScheme="neutral-2"
            compact
            data-id={DataId.CloseCommentMenuModalIconButton}
            data-parent={DataId.Commenting}
            onClick={handleCloseModal}
          >
            <Icon path={mdiClose} size={8} />
          </IconButton>
        </div>
        <div className="flex justify-end gap-x-2">
          <Button
            className="!border-transparent"
            colorScheme="neutral-2"
            data-id={buttonConfig.cancelButton['data-id']}
            data-parent={buttonConfig.cancelButton['data-parent']}
            onClick={handleCloseModal}
            variant="outlined"
          >
            <span className="text-neutral-4">{CANCEL_BUTTON_TEXT}</span>
          </Button>
          <Button
            data-id={buttonConfig.actionButton['data-id']}
            data-parent={buttonConfig.actionButton['data-parent']}
            onClick={onConfirmAction}
          >
            {actionButtonText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
