import { type NestedComment } from '@/types/Comments';

const isModerated = (comment: NestedComment) =>
  comment.moderationStatus !== 'PENDING' &&
  comment.moderationStatus !== 'REJECTED';

const isCurrentUserComment = (comment: NestedComment, userId: string) =>
  comment.userReference.id === userId;

const isNotDeletedOrHasReplies = (
  comment: NestedComment,
  replies: NestedComment[],
) => comment.action !== 'DELETE' || (replies && replies.length > 0);

const isActiveComment = (
  comment: NestedComment,
  userId: string,
  replies: NestedComment[],
): boolean => {
  const isUserComment = isCurrentUserComment(comment, userId);

  return (
    isNotDeletedOrHasReplies(comment, replies) &&
    (isUserComment || (isModerated(comment) && !isUserComment))
  );
};

export const filterNestedComments = (
  comments: NestedComment[],
  userId = '',
): NestedComment[] =>
  comments
    .map((comment) => ({
      ...comment,
      replies: {
        ...comment.replies,
        replies: comment.replies
          ? filterNestedComments(comment.replies.replies, userId)
          : [],
      },
    }))
    .filter((comment) =>
      isActiveComment(comment, userId, comment.replies.replies),
    );
