import { type FunctionComponent } from 'react';

import { type AdSize } from '@/types/AdSizes';

import ConfigurableSlot from '@/utils/ads/googlePublisherTag/ConfigurableAdSlot';

import { type StmAdProps } from './StmAd.props';

const FluidAdSize: AdSize[] = ['fluid'];

const DesktopFluidSizeMap: googletag.SizeMappingArray = [
  [[1024, 0], ['fluid']], // Desktop
  [[768, 0], ['fluid']], // Tablet
  [[0, 0], []], // Mobile (no ads)
];

const MobileFluidSizeMap: googletag.SizeMappingArray = [
  [[1024, 0], []], // Desktop (no ads)
  [[0, 0], ['fluid']], // Mobile
];

const STM_AD_TARGETING = { fluid: 'stm' };

const StmAd: FunctionComponent<StmAdProps> = ({ isMobile, pageTargeting }) => {
  const adSizeMap = isMobile ? MobileFluidSizeMap : DesktopFluidSizeMap;

  return (
    <ConfigurableSlot
      className="[&_iframe]:shadow-lg"
      pageTargeting={pageTargeting}
      size={FluidAdSize}
      sizeMapping={adSizeMap}
      targeting={STM_AD_TARGETING}
    />
  );
};

export default StmAd;
