'use client';

import { type FunctionComponent } from 'react';
import { Typography } from '@motortrend/ids';
import cx from 'clsx';

import CommentMenu from '../CommentMenu';
import { type CommentHeaderProps } from './CommentHeader.props';

const CommentHeader: FunctionComponent<CommentHeaderProps> = ({
  onModalAction,
  onSwitchToEditMode,
  showCommentMenu,
  userName,
}) => {
  return (
    <div
      className={cx('mb-2 flex h-6 items-center justify-start', {
        'justify-between': showCommentMenu,
      })}
    >
      <Typography variant="subtitle1">{userName}</Typography>
      {showCommentMenu ? (
        <CommentMenu
          onModalAction={onModalAction}
          onSwitchToEditMode={onSwitchToEditMode}
        />
      ) : null}
    </div>
  );
};

export default CommentHeader;
