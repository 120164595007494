'use client';

import { useEffect, type FunctionComponent } from 'react';
import { env } from '@/config';
import { withErrorBoundary } from 'react-error-boundary';

import loadScript from '@/utils/onetrust/loadScript';
import replacePlaceholders from '@/utils/replacePlaceholders';
import { NoUiErrorFallback } from '@/components/ErrorFallback';

const SkimLinkInjector: FunctionComponent = () => {
  useEffect(() => {
    const addSkimlinkScript = () => {
      const { id, url } = env.skimLinks;

      try {
        loadScript(replacePlaceholders(url, { id }));
      } catch (error) {
        console.error('Error injecting Skimlinks script:', error);
      }
    };

    addSkimlinkScript();
  }, []);

  return null;
};

export default withErrorBoundary(SkimLinkInjector, {
  FallbackComponent: NoUiErrorFallback,
});
