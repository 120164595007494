import {
  type BaseCommentPost,
  type UserCommentProfile,
} from '@/types/Comments';

export const createBaseCommentPost = (
  assetId: string,
  assetType: string,
  userId: string,
  userCommentProfile: UserCommentProfile['data'],
  text: string,
): BaseCommentPost => ({
  assetReference: {
    brand: 'MOTORTREND',
    id: assetId,
    type: assetType,
  },
  text,
  type: 'comment',
  useriq: {
    context: {
      application: 'motortrendCom',
      brand: 'MOTORTREND',
      device_type: 'ios',
      platform: 'web',
    },
  },
  userReference: {
    id: userId,
    imageUrl: userCommentProfile.avatar,
    name: userCommentProfile.userName,
  },
});
