import { type NestedComment } from '@/types/Comments';

export const getNestedCommentById = (
  comments: NestedComment[],
  commentId: string,
): NestedComment | undefined => {
  const findComment = (
    comments: NestedComment[],
  ): NestedComment | undefined => {
    for (const comment of comments) {
      if (comment.id === commentId) {
        return comment;
      }
      const nestedComment = findComment(comment.replies.replies);
      if (nestedComment) {
        return nestedComment;
      }
    }
    return undefined;
  };

  return findComment(comments);
};
