import { type NestedComment } from '@/types/Comments';

import { getNestedCommentById } from './getNestedCommentById';

export const updateNestedComments = (
  comments: NestedComment[],
  commentId: string,
  updateCallback: (comment: NestedComment) => NestedComment,
): NestedComment[] => {
  const foundComment = getNestedCommentById(comments, commentId);

  if (!foundComment) {
    return comments;
  }

  return comments.map((comment) => {
    if (comment.id === foundComment?.id) {
      return updateCallback(comment);
    } else if (comment.replies.totalCount > 0) {
      return {
        ...comment,
        replies: {
          ...comment.replies,
          replies: updateNestedComments(
            comment.replies.replies,
            commentId,
            updateCallback,
          ),
        },
      };
    }
    return comment;
  });
};
