import { COMMENTS_STORAGE_KEY, COMMENTS_STORAGE_KEY_ROOT } from './constants';

export const createStorageKey = (
  parentCommentId: string | null = '',
  rootId = '',
): string | undefined => {
  if (parentCommentId) {
    return `${COMMENTS_STORAGE_KEY}${parentCommentId}`;
  } else if (rootId) {
    return `${COMMENTS_STORAGE_KEY_ROOT}${rootId}`;
  }
  return undefined;
};
