'use client';

import { useRef, type ChangeEvent, type FunctionComponent } from 'react';
import { CommentsTextAreaStrings as strings } from '@/translations';
import { TextField } from '@motortrend/ids';
import cx from 'clsx';

import { createStorageKey } from '@/utils/comments/createStorageKey';

import { type CommentsTextAreaProps } from './CommentsTextArea.props';

const CommentsTextArea: FunctionComponent<CommentsTextAreaProps> = ({
  commentText,
  isInEditMode,
  isReply,
  onTextAreaChange,
  onTextAreaFocus,
  parentCommentId,
  rootId,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const setCursorToEnd = () => {
    setTimeout(() => {
      if (textareaRef.current) {
        const { length } = textareaRef.current.value;
        textareaRef.current.selectionStart = length;
        textareaRef.current.selectionEnd = length;
      }
    }, 0);
  };

  const handleTextAreaFocus = () => {
    onTextAreaFocus?.();
    setTimeout(setCursorToEnd, 0);
  };

  const handleTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;

    onTextAreaChange(value);

    const storageKey = createStorageKey(parentCommentId, rootId);

    if (!isInEditMode && storageKey) {
      sessionStorage.setItem(storageKey, value);
    }
  };

  return (
    <div
      className={cx('flex w-full items-center justify-end p-0 py-6', {
        'pl-4': isReply,
      })}
    >
      <TextField
        as="textarea"
        autoFocus={isReply}
        className="w-full"
        name="comment-text"
        onChange={handleTextAreaChange}
        onFocus={handleTextAreaFocus}
        placeholder={strings.AddCommentPlaceholder}
        ref={textareaRef}
        value={commentText}
      />
    </div>
  );
};

export default CommentsTextArea;
