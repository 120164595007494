import { type NestedComment } from '@/types/Comments';

export const addNestedCommentAsReply = (
  newComment: NestedComment,
  nestedComments: NestedComment[],
  parentId: string,
): NestedComment[] => {
  const addReplyToComment = (comment: NestedComment): NestedComment => {
    if (comment.id === parentId) {
      return {
        ...comment,
        replies: {
          replies: [newComment, ...comment.replies.replies],
          totalCount: comment.replies.totalCount + 1,
        },
      };
    } else if (comment.replies && comment.replies.replies.length > 0) {
      return {
        ...comment,
        replies: {
          ...comment.replies,
          replies: comment.replies.replies.map(addReplyToComment),
        },
      };
    }
    return comment;
  };

  return nestedComments.map(addReplyToComment);
};
