import getPathSegments from '@/utils/nitrous/getPathSegments';
import titleCase from '@/utils/titleCase';

import { ViewGroup } from './constants';

const getArticlePageViewGroup = (path: string): string => {
  const cleanedPath = path.startsWith('/hotrod')
    ? path.replace('/hotrod', '')
    : path;

  const segments = getPathSegments(cleanedPath);

  if (segments.length <= 1) {
    return ViewGroup.NoTaxonomy;
  }

  // Convert dashes to spaces and capitalize words
  const viewGroup = titleCase(segments[0].replaceAll('-', ' '));

  return viewGroup;
};

export default getArticlePageViewGroup;
