import { type FunctionComponent } from 'react';
import { Routes } from '@/constants';
import { CommentHeaderStrings as strings } from '@/translations';
import { mdiMessageReplyText } from '@mdi/js';
import Icon from '@mdi/react';
import { Link, Typography } from '@motortrend/ids';

import { DataId } from '@/utils/nitrous/constants';

const CommentsHeader: FunctionComponent = () => (
  <>
    <div className="flex items-center gap-1">
      <Icon horizontal path={mdiMessageReplyText} size={1} />
      <Typography variant="h5">{strings.HeaderText}</Typography>
    </div>
    <Typography variant="caption1">
      {strings.DisclaimerText}
      <Link
        className="ml-1 underline hover:text-primary-2"
        data-id={DataId.CommentingGuidelines}
        data-parent={DataId.Commenting}
        href={Routes.TermsOfUse}
      >
        {strings.CommunityGuidelinesText}
      </Link>
      .
    </Typography>
  </>
);

export default CommentsHeader;
