const scrollToElement = (elementId: string) => {
  if (document && document.getElementById(elementId)) {
    document.getElementById(elementId)?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
};

export default scrollToElement;
