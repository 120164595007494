import { type AdElement } from '@/types/Article';

export const adElementType: Record<string, AdElement['adType']> = {
  integrated: 'integrated',
  integratedMobile: 'integrated-mobile',
  mobileMrec: 'mobile-mrec',
  ntv: 'ntv',
  ntvMobile: 'ntv-mobile',
  stm: 'stm',
  stmMobile: 'stm-mobile',
};
