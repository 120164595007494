import { useCallback, useState } from 'react';

import {
  type AddCommentPost,
  type BaseComment,
  type Comment,
  type NestedComment,
} from '@/types/Comments';

import { addNestedCommentAsReply } from '@/utils/comments/addNestedCommentAsReply';
import { createNestedComment } from '@/utils/comments/createNestedComment';
import { filterNestedComments } from '@/utils/comments/filterNestedComments';
import { mapNestedComments } from '@/utils/comments/mapNestedComments';
import { updateNestedComments } from '@/utils/comments/updateNestedComments';

const useNestedCommentsState = (userId: string) => {
  const [nestedComments, setNestedComments] = useState<NestedComment[]>([]);

  const processAndSetNestedComments = useCallback(
    (comments: Comment[]) => {
      const nestedComments = mapNestedComments(comments);
      const filteredNestedComments = filterNestedComments(
        nestedComments,
        userId,
      );

      setNestedComments(filteredNestedComments);
    },
    [userId, setNestedComments],
  );

  const addNestedComment = useCallback(
    (
      newCommentId: string,
      newCommentPost: AddCommentPost,
      error: BaseComment['error'],
    ) => {
      const parentId = newCommentPost.parentId || '';
      const newComment = createNestedComment(
        newCommentId,
        newCommentPost,
        error,
      );

      setNestedComments((prevNestedComments) =>
        parentId
          ? addNestedCommentAsReply(newComment, prevNestedComments, parentId)
          : [newComment, ...prevNestedComments],
      );
    },
    [],
  );

  const updateNestedComment = useCallback(
    (commentId: string, updates: Partial<BaseComment>) => {
      setNestedComments((prevNestedComments) => {
        const newNestedComments = updateNestedComments(
          prevNestedComments,
          commentId,
          (comment) => ({
            ...comment,
            ...updates,
          }),
        );

        return newNestedComments;
      });
    },
    [setNestedComments],
  );

  return [
    nestedComments,
    processAndSetNestedComments,
    addNestedComment,
    updateNestedComment,
  ] as const;
};

export default useNestedCommentsState;
