'use client';

import { useEffect, useId, useState, type FC } from 'react';
import { usePathname } from 'next/navigation';
import cx from 'clsx';

import { type AdSize } from '@/types/AdSizes';

import useAdSlot from '@/utils/ads/useAdSlot';
import { usePhotoGalleryContext } from '@/components/PhotoCollection/PhotoGalleryContext';
import StickyAd from '@/components/StickyAd';

import { type ArticleTopAdProps } from './ArticleTopAd.props';

const adSizes: Record<string, AdSize[]> = {
  afterMarketDesktop: [
    [970, 250],
    [970, 90],
    [728, 90],
  ],
  afterMarketMobile: [[320, 250]],
  inmarketDesktop: [
    [970, 90],
    [728, 90],
  ],
  inmarketMobile: [[320, 50]],
};

const sizeMapping: Record<string, googletag.SizeMappingArray> = {
  afterMarketDesktop: [
    [
      [1024, 0],
      [
        [970, 250],
        [970, 90],
        [728, 90],
      ],
    ], // Desktop and Laptop
    [
      [768, 0],
      [
        [970, 250],
        [970, 90],
        [728, 90],
      ],
    ], // Tablet
    [[0, 0], []], // Tablet and Mobile (no ads)
  ],
  afterMarketMobile: [
    [[1024, 0], []], // Desktop (no ads)
    [
      [768, 0],
      [300, 250],
    ], // Tablet
    [
      [0, 0],
      [300, 250],
    ], // Mobile
  ],
  inmarketDesktop: [
    [
      [1024, 0],
      [
        [970, 90],
        [728, 90],
      ],
    ], // Desktop and Laptop
    [
      [768, 0],
      [
        [970, 90],
        [728, 90],
      ],
    ], // Tablet
    [[0, 0], []], // Tablet and Mobile (no ads)
  ],
  inmarketMobile: [
    [[1024, 0], []], // Desktop (no ads)
    [
      [768, 0],
      [300, 50],
    ], // Tablet
    [
      [0, 0],
      [320, 50],
    ], // Mobile
  ],
};

const ArticleTopAd: FC<ArticleTopAdProps> = ({
  isAfterMarket,
  isGallery,
  isSponsored,
  pageTargeting,
}) => {
  const { currentPhotoIndex, currentTab } = usePhotoGalleryContext();
  const [adKey, setAdKey] = useState(`${currentPhotoIndex}-${currentTab}`);
  const generatedId = useId();
  const pathname = usePathname();
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [pathname]);

  useEffect(() => {
    setAdKey(`${currentPhotoIndex}-${currentTab}-${Date.now()}`);
  }, [currentPhotoIndex, currentTab]);

  const adConfig = {
    desktop: {
      className: 'hidden justify-center lg:flex lg:min-h-[90px] lg:min-w-full',
      sizeMapping:
        sizeMapping[isAfterMarket ? 'afterMarketDesktop' : 'inmarketDesktop'],
      sizes: adSizes[isAfterMarket ? 'afterMarketDesktop' : 'inmarketDesktop'],
      targeting: { pos: ['top', '1'] },
    },
    mobile: {
      className: cx('flex justify-center lg:hidden', {
        'min-w-[300px] min-h-[250px]': isAfterMarket,
        'min-w-[320px] min-h-[50px]': !isAfterMarket,
      }),
      sizeMapping:
        sizeMapping[isAfterMarket ? 'afterMarketMobile' : 'inmarketMobile'],
      sizes: adSizes[isAfterMarket ? 'afterMarketMobile' : 'inmarketMobile'],
      targeting: isAfterMarket
        ? { pos: ['mobile_header'] }
        : { pos: ['top', '1'] },
    },
  };

  const mobileId = `article-top-ad-mobile-${generatedId}`;
  const desktopId = `article-top-ad-desktop-${generatedId}`;

  useAdSlot({
    id: mobileId,
    path: pageTargeting.adPath,
    size: adConfig.mobile.sizes,
    sizeMapping: adConfig.mobile.sizeMapping,
    targeting: { ...pageTargeting.adTargeting, ...adConfig.mobile.targeting },
  });

  useAdSlot({
    id: desktopId,
    path: pageTargeting.adPath,
    size: adConfig.desktop.sizes,
    sizeMapping: adConfig.desktop.sizeMapping,
    targeting: { ...pageTargeting.adTargeting, ...adConfig.desktop.targeting },
  });

  const topAd = (
    <div
      className="align-center flex justify-center bg-neutral-7 transition-colors dark:bg-neutral-3"
      key={adKey || key}
    >
      <div className={adConfig.desktop.className} data-ad id={desktopId} />
      <div className={adConfig.mobile.className} data-ad id={mobileId} />
    </div>
  );

  const isSticky = !((isSponsored && isGallery) || isAfterMarket);

  return isSticky ? <StickyAd className="z-40">{topAd}</StickyAd> : topAd;
};

export default ArticleTopAd;
